var Quiz = (function () {
    'use strict';
    /* ----- Declare Variables ---------------------------------------------------------------------------------------- */
    var
        checked = 0,
        points = 0,
        timeCurrent = 0,
        timeMax = 0,
        submitted = false,
        timer = null,
        $templateQuizRadio,
        $templateQuizForm,
        $templateQuizRadioItem;
    var min, max;

    let groupvote = false;

    localState.quiz = localState.quiz || {};
    localState.quiz.itemIndex = localState.quiz.itemIndex || 0;

    /* ----- Init Quiz --------------------------------------------------------------------------------------------- */
    function Init() {
        $templateQuizRadio = $('template#tplQuizRadio').contents();
        $('template#tplQuizRadio').remove();

        $templateQuizRadioItem =  $('template#tplQuizRadioItem').contents();
        $('template#tplQuizRadioItem').remove();

        $templateQuizForm = $('template#tplQuizForm').contents();
        $('template#tplQuizForm').remove();

        renderDetails();

        showShownQuiz(false);

        delete Quiz.Init;
    }

    function showShownQuiz(fromsocket) {

        if (fromsocket) {
            BuildFromSource(function () {
                getShownQuiz();
            });
        } else {
            getShownQuiz();
        }
    }

    function getShownQuiz() {
        let shown = AppData.quiz.find(function (item){
            return item.selected && (item.pushChannel == localState.currentChannel || item.pushChannel == "all");
        });

        if (shown) {
            showQuestion(shown.id);
            if (shown.start) {
                startTimer();
            }
        } else {
            hideQuestion();
        }
    }

    /* ----- Build Details -------------------------------------------------------------------------------------------- */
    function renderDetails(group) {
        points = timeCurrent = timeMax = 0;
        submitted = false;
        $('#quiz')
            .find('header .close-main')
            .parent().html('<h3 class="text-upper text-bold">' + Language.getItem('quiz-title') + '</h3>');
        $('#quiz').find('header .close-main').remove();
        $('#quiz').find('header .hamburger').remove();

        $('#quiz').find('.closebutton').remove();

        $('#quiz').find('header .logo').css('pointerEvents', 'none');

        groupvote = group != undefined;

        if ('quiz' in localState) {
            var
                $details = $('#quiz article'),
                $header = $('#quiz header'),
                $progress = $('#quiz progress'),
                $fragmentDetails = $(document.createDocumentFragment()),
                $clone,
                $cloneItem;

            let quizzes = AppData.quiz.filter(function(item){
                if (group == undefined) {
                    return item.id == localState.quiz.itemIndex;
                } else {
                    return item.group == group;
                }
            });

            $("#quiz .data-title-group").html("");

            let groupItem;
            if (group != undefined) {
                groupItem = AppData.quizgroups.find(function(gi){
                    return gi.id == group;
                });

                if (groupItem) {
                    $("#quiz .data-title-group").html(groupItem.title[localState.lang]);
                }
            }

            $("#quiz").find(".forms").empty();
            let $formclone;

            for (let q in quizzes) {
                let data = AppData.quiz.find(function (el) {
                    return el.id === quizzes[q].id
                }) || AppData.quiz[0];

                if (!data) {
                    return false;
                }

                $formclone = $templateQuizForm.clone();

                $formclone.attr("data-quizid", data.id);
                $formclone.attr("data-submitted", "not");
                $formclone.find('[name=id]').val(data.id);
                $formclone.find('[name=quiz]').val(data.id);
                $formclone.find('[type=submit] span').html(Language.getItem("save"));
                $formclone.find('.not_voted_text').html(Language.getItem("not-voted-num"));
                $formclone.find('.vote_not_allowed').html(Language.getItem("vote-not-allowed"));

                $fragmentDetails = $(document.createDocumentFragment());

                $('#quiz .not_voted').addClass("hidden");

                let timer;
                if (group == undefined) {
                    timer = parseInt(data.timer);
                } else {
                    timer = parseInt(groupItem.timer);
                }

                if (timer > 0) {
                    $formclone.find('[type=submit]').addClass("hidden");
                } else {
                    if (AppData.profile.can_vote == "1") {
                        $formclone.find('[type=submit]').removeClass("hidden");
                    }
                }
                if (AppData.profile.can_vote != "1") {
                    $formclone.find(".vote_not_allowed").removeClass("hidden");
                } else {
                    $formclone.find(".vote_not_allowed").addClass("hidden");
                }

                min = data.min_answers;
                max = data.max_answers;

                timeCurrent = timeMax = parseFloat(timer);

                if (timeMax > 0) {
                    $header.find('.data-label').text(timeCurrent.toFixed(0)).show();
                    $header.find('.data-icon').addClass('fas fa-stopwatch').show();
                } else {
                    $header.find('.data-label').hide();
                    $header.find('.data-icon').hide();
                }

                var answer_id = 0;
                if (AppData.profile.quiz_answers) {
                    AppData.profile.quiz_answers.sort(function (a, b) {
                        return a.created_at < b.created_at;
                    });

                    var answer = AppData.profile.quiz_answers.find(function (qa) {
                        return qa.quiz === data.id;
                    });
                    if (answer) {
                        answer_id = answer.id;
                    }
                }

                $progress.attr('value', timeCurrent.toFixed(2));
                $progress.attr('max', timeMax);
                $clone = $templateQuizRadio.clone();

                $formclone.find('.data-title-quiz').html(data.question[localState.lang]);

                $.each(data.answer, function (i, part) {
                    $cloneItem = $templateQuizRadioItem.clone();

                    $cloneItem.toggleClass('correct', part.correct);
                    if (timer > 0) {
                        $cloneItem.find('input').attr('disabled', 'disabled');
                    } else {
                        $cloneItem.find('input').removeAttr('disabled');
                    }
                    $cloneItem.attr("data-points", part.points);
                    var label = "";
                    if (part[localState.lang] != undefined) {
                        if (part[localState.lang].image !== "") {
                            label += '<img src="' + part[localState.lang].image + '">';
                        }
                        if (part[localState.lang].text !== "") {
                            label += '<div>' + part[localState.lang].text + '</div>';
                        }
                    }

                    $cloneItem.find('.data-label').html(label);
                    $cloneItem.find('input').val(part.id);

                    $cloneItem.appendTo($clone);
                });

                $clone.appendTo($fragmentDetails);
                $formclone.find('.items').html($fragmentDetails);

                $formclone.appendTo($("#quiz").find(".forms"));

                $formclone.find('.survey-radio').addClass(data.layout);
                $formclone.find('[type=submit]').addClass('disabled');
                $formclone.find('form').show();
                //$formclone.find('.done').hide();

                $clone = null;
            }
        }
    }

    /* ----- Build from File ------------------------------------------------------------------------------------------ */
    function BuildFromSource(callback) {
        $.post('api/?v='+Math.random(), {
            do: 'getQuiz'
        }, function (data) {
            AppData.quiz = data;
            data = null;

            if (callback != undefined && typeof callback == "function") {
                callback();
            }
        });
    }

    function updateQuizGroups() {
        $.post('api/', {
            do: 'getQuizGroups'
        }, function (data) {
            AppData.quizgroups = data;
            data = null;
        });
    }

    /* ----- Event: Change Input -------------------------------------------------------------------------------------- */
    function evtChangeInput() {

        if (AppData.profile.can_vote != "1") {
            return;
        }

        let form = $(this).closest('form')
        var $label = $(this).closest('label');
        var classList = 'state-checked animated pulse';

        checked = $(".quiz_answer input[type=checkbox]:checked", form).length;

        if (max == 1) {
            $(".quiz_answer input[type=checkbox]", form).prop("checked", false);
            $(".quiz_answer", form).toggleClass(classList, false);
            $(this).prop("checked", true);
        } else {
            if (checked > max) {
                $(this).prop("checked", false);
            }
        }

        $label.toggleClass(classList, $(this).prop("checked"));

        checked = $(".quiz_answer input[type=checkbox]:checked", form).length;

        if (checked >= min) {
            $(this).closest('form').find('[type=submit]').removeClass('disabled');
        } else {
            $(this).closest('form').find('[type=submit]').addClass('disabled');
        }

        points = 0;
        form.find('.state-checked').each(function () {
            var p = parseInt($(this).attr("data-points"));
            points += isNaN(p) ? 0 : p;
        });
    }

    /* ----- Event: Submit Form --------------------------------------------------------------------------------------- */
    function evtSubmitForm(e) {
        e.preventDefault();
        if (!groupvote) {
            clearTimeout(timer);
        }

        if (AppData.profile.can_vote != "1") {
            return;
        }

        var
            $this = $(this),
            form = $this.closest("form"),
            formData = $this.serializeObject() || {};
        var classList = 'state-checked animated pulse';
        var fData = new FormData($(this).get(0));

        form.attr("data-submitted", "submitted");

        writeDbLog("submit", formData.id, "user_logs", "quiz");

        submitted = true;
        fData.append("points", points);
        if (points > 0) {
            fData.append("time", (timeMax - timeCurrent).toFixed(2) || 1.00);
        } else {
            fData.append("time", timeMax.toFixed(2));
        }

        fData.append("do", "sendQuizPoints");

        $this.find('[type=checkbox]').attr('disabled', 'disabled');
        $this.find('[type=submit]').addClass("hidden");

        $.ajax({
            method: 'post',
            url: 'api/',
            processData: false,
            contentType: false,
            data: fData,
            success: function (data) {
                if (data.error) {
                    Modal.alert(Language.getItem(data.error));
                    $(".quiz_answer input[type=checkbox]", form).prop("checked", false);
                    $(".quiz_answer", form).toggleClass(classList, false);

                    if (data.group_only_correct == "yes") {
                        $this.find('[type=checkbox]').prop('disabled', false);
                        $this.find('[type=submit]').removeClass("hidden");
                    }
                }

                if (groupvote) {
                    if (data.voted == data.group_max) {
                        clearTimeout(timer);
                    }
                }
            }
        });
    }

    /* ----- Event: Enable Submit on Input ---------------------------------------------------------------------------- */
    function evtInput() {
        if (checked.length >= min) {
            $(this).closest('form').find('[type=submit]').removeClass('disabled');
        }
    }

    /* ----- Bind Events ---------------------------------------------------------------------------------------------- */
    $(document)
        .on('change', '#quiz form input', evtInput)
        .on('change', '#quiz input[type=checkbox]', evtChangeInput)
        .on('submit', '#quiz form', evtSubmitForm);

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    function showQuestion(id) {
        if (id) {
            if (id.group == undefined) {
                localState.quiz.itemIndex = String(id);
            }
            clearTimeout(timer);
            renderDetails(id.group);
            Layout.show('quiz');
        }
    }

    function hideQuestion() {
        clearTimeout(timer);
        Layout.hide('quiz');
    }

    function renderTimer() {
        var
            $progress = $('#quiz progress'),
            $header = $('#quiz header .data-label');
        $progress.attr('value', timeCurrent.toFixed(2));
        $header.text(timeCurrent.toFixed(0));
        $progress = $header = null;

    }

    function startTimer() {
        if (timeMax > 0) {
            var $self = $('#quiz article');
            clearTimeout(timer);
            timeCurrent = parseFloat(timeMax);
            renderTimer();
            if (AppData.profile.can_vote == "1") {
                $self.find('[type=checkbox]').removeAttr('disabled');
                $self.find('[type=submit]').removeClass("hidden");
            }

            timer = setTimeout(tick, 1000);
        }
    }

    function tick() {
        var
            $self = $('#quiz article');
        if (timeCurrent <= 0 || !$self.is(':visible')) {
            clearTimeout(timer);
            if (groupvote) {
                let forms = $self.find('form');
                forms.each(function () {
                    if ($(this).attr("data-submitted") != "submitted") {
                        sendFormAuto($(this));
                        //$(this).trigger('submit');
                    }
                });
            } else {
                if (!submitted) {
                    sendFormAuto($self.find('form'));
                    //$self.find('form').trigger('submit');
                }
            }
            //$self.find('[type=checkbox]').attr('disabled','disabled');
            return false;
        }
        //timeCurrent = (Math.round((timeCurrent - 0.01) * 100) / 100);
        timeCurrent = timeCurrent - 1;
        timer = setTimeout(tick, 1000);
        renderTimer();
        $self = null;
    }

    function sendFormAuto(form) {
        setTimeout(function(){
            form.trigger("submit");
            $('#quiz article').find('[type=checkbox]').attr('disabled','disabled');
        }, Math.floor(Math.random() * (1500 - 100 + 1) + 100));
    }

    function showResults(results) {
        var $self = $('#quiz article');
        $self.find('[type=checkbox]').attr('disabled','disabled');
        $self.find('[type=submit]').addClass("hidden");

        for (let r in results) {
            var data = results[r];
            if (!data) {
                return false;
            }

            $.each(data, function (i, part) {

                let parent = $("[data-quizid='"+r+"']");

                $('.items .item label', parent).eq(i).attr('data-result', parseFloat(part.result).toFixed(1) + '%').addClass("show_result");
                let result_text = parseFloat(part.result).toFixed(1) + '%';
                switch (part.resultas) {
                    case "percentage":
                        result_text = parseFloat(part.result).toFixed(1) + '%';
                        break;
                    case "count":
                        result_text = part.count + ' ' + AppData.languages[localState.lang]['quiz-vote'];
                        break;
                    case "both":
                        result_text = part.count + ' ' + AppData.languages[localState.lang]['quiz-vote'] + ' (' + parseFloat(part.result).toFixed(1) + '%)';
                        break;
                }

                if (AppData.modulsettings.show_not_voted_count) {
                    $('.not_voted', parent).removeClass("hidden");
                    $('.not_voted .not_voted_num', parent).html(parseInt(part.can_vote) - parseInt(part.total)).show();
                }
                $('.items .item label', parent).eq(i).find(".result_container").html('<div class="result_container_text">' + result_text + '</div><div class="result_container_progress"></div>');
                $('.items .item label', parent).eq(i).find(".result_container_progress").css('width', parseFloat(part.result).toFixed(1) + '%');
            });
        }
    }

    function showCorrect() {
        var $self = $('#quiz article');
        $self.find('[type=checkbox]').attr('disabled','disabled');
        $self.find('[type=submit]').addClass("hidden");
        $('#quiz .items .item label.correct').addClass('marked');
    }

    function testVote(send_auto) {
        var $self = $('#quiz:visible');
        if ($self.length) {
            var max_answers = $self.find("label").length;
            $self.find('label').eq(Math.floor(Math.random() * (max_answers - 1))).trigger('click');
            if (send_auto === true) {
                setTimeout(function () {
                    $self.find('[type=submit]').trigger('click');
                }, (Math.floor(Math.random() * 5) + 1) * 1000);
            }
        }
    }

    return {
        Init: Init,
        BuildFromSource: BuildFromSource,
        showQuestion: showQuestion,
        showResults: showResults,
        showCorrect: showCorrect,
        hideQuestion: hideQuestion,
        startTimer: startTimer,
        testVote: testVote,
        updateQuizGroups: updateQuizGroups,
        showShownQuiz: showShownQuiz
    };
})();
